import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/about-3.jpg';
// import blogd2 from '../../assets/images/2024/lagoon-partners/2.jpeg';
// import blogd3 from '../../assets/images/2024/lagoon-partners/3.jpeg';
// import blogd4 from '../../assets/images/2024/lagoon-partners/4.jpeg';
// import blogd5 from '../../assets/images/2024/lagoon-partners/5.jpeg';
// import blogd6 from '../../assets/images/2024/lagoon-partners/6.jpeg';
// import blogd7 from '../../assets/images/2024/lagoon-partners/7.jpeg';
// import blogd8 from '../../assets/images/2024/lagoon-partners/8.jpeg';
// import blogd9 from '../../assets/images/2024/lagoon-partners/9.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  //   const subs = [
  //     {
  //       SNO: '1',
  //       PRESENTERS: 'DR. A.K Toye',
  //       SESSION: 'CASE 1',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. O.D Tom- AYEGUNLE',
  //       SESSION: 'CASE 2',
  //     },
  //     {
  //       SNO: '3',
  //       PRESENTERS: 'DR. T. AYO- Olagunju',
  //       SESSION: 'Timing of initiation of Dialysis AKI',
  //     },
  //     {
  //       SNO: '4',
  //       PRESENTERS: 'Dr. D. Ezuduemoih',
  //       SESSION: 'Timing of initiation of Dialysis CKD',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. B. T. BELLO/ Dr. R. W. BRAIMOH',
  //       SESSION: 'Overview',
  //     },
  //   ];

  //   const subRows = subs.map((element) => (
  //     <tr key={element.SNO}>
  //       <td>{element.SNO}</td>
  //       <td>{element.PRESENTERS}</td>
  //       <td>{element.SESSION}</td>
  //     </tr>
  //   ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Re: How we lost a good man to Nigeria’s terrible Healthcare
                  System
                </h2>
                <h6>
                  PRESS RELEASE <br />
                  {/* Re: How we lost a good man to Nigeria’s terrible Healthcare
                  System */}
                </h6>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The attention of the Management of Lagos University Teaching
                  Hospital has been drawn to a viral news on the death of Mr.
                  Olaleye Franklin Adenibuyan published online on 16th February
                  2024.
                  <br />
                  The Hospital commiserates with the family of Late Mr.
                  Adenibuyan on his death and we extend our condolences to his
                  family on the loss of their father, and husband. However, as a
                  responsible Management, there is a need to respond to some of
                  the issues raised by the writer of the article, while bearing
                  in mind the need to protect the privacy/confidentiality of the
                  deceased.
                  <br />
                  Late Mr. Adenibuyan was admitted deeply unconscious into our
                  facility on the 15th of January 2024. He was promptly attended
                  to at the Emergency Department of LUTH and stabilized by a
                  team of Neurologists and Neurosurgeons. He was thereafter
                  transferred to the Intensive Care Unit (ICU) of the Hospital
                  for critical care as he was deeply unconscious. In the ICU,
                  his care was coordinated by a team of Neurosurgeons,
                  Intensivists and ICU Nurses. Throughout his stay in the
                  Hospital, the managing team was in direct and constant contact
                  with Mr. Adenibuyan’s immediate family, including his wife and
                  son. Our records reveal that he was given prompt, appropriate
                  and professional care for his condition by senior medical
                  professionals.
                  <br />
                  It is not uncommon for hospital equipment to experience
                  downtime, and LUTH has backup arrangements for when such
                  downtimes occur. In situations when a hospital equipment
                  experiences downtime, the Hospital policy is for an ambulance,
                  and appropriate healthcare professionals to be made available
                  to take the patient to a sister facility for the required
                  procedure to be carried out. This was clearly communicated to
                  Mr. Adenibuyan’s family when he needed a repeat CT scan. For
                  the records, LUTH has functional imaging facilities including,
                  MRI, Xray, ultrasonography, fluoroscopy and mammography
                  machines.
                  <br />
                  {/* <Table fontSize='md' striped>
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>PRESENTERS</th>
                        <th>SESSION</th>
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table> */}
                  Deployment of equipment such as the intracranial pressure
                  (ICP) monitor requires consumables such as probes. Such
                  consumables are supplied by private vendors and the costs
                  borne by the patient for which they are used. It is these
                  consumables that were required to be made available in this
                  case. The decision on timing of deployment of the ICP monitor
                  was taken by the Neurosurgeons, with due consideration given
                  to the potential benefits of ICP monitoring versus the
                  potential risks that may arise from placing a foreign body
                  within the brain. Ultimately, a decision to intervene
                  surgically was taken, and, while preparations for this were
                  ongoing, the patient suffered a cardiac arrest from which he
                  could not be successfully resuscitated.
                  <br />
                  The establishment of a Cardiac Center at LUTH aligns with the
                  government's mandate, as shared by the Honorable Minister.
                  <br />
                  In the same vein, the involvement of the National Medical
                  College further solidifies the commitment to advancing
                  Healthcare Services.
                  <br />
                  Prof. Adeyemo revealed that Cardiac Care topped LUTH's
                  infrastructure priorities, leading to the Hospital's proactive
                  plans and engagement in the collaborative effort.
                  <br />
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                The Hospital has several internal mechanisms for reviewing
                patient outcomes in a manner that enables us to improve on
                services rendered, validate / critique clinical decisions and
                ensure that these improvements impact patient outcomes
                positively. A Committee has since been set up to investigate the
                allegations bandied around in this particular case.
                <br />
                Contrary to the impression given in the publication, The Lagos
                University Teaching Hospital remains a pride to the country. It
                has undergone a renaissance, rather than a rot in recent years
                and continues to improve its services to the public. LUTH is a
                publicly funded tertiary healthcare facility and the Federal
                Government of Nigeria (FGN) has committed to repositioning the
                Nigerian health sector for improved service delivery to
                Nigerians. For the avoidance of doubt, the FGN has allocated
                significant resources to revamping the health sector in the last
                4 years, and the present government under the leadership of
                President Bola Ahmed Tinubu, GCFR is committed to doing same.
                LUTH has greatly benefitted from these funds, and has used it
                judiciously in upgrading our infrastructure and equipment. We
                remain the only public tertiary health institution with 24-hour
                electricity supply since 2017, made possible through an
                Independent Power project (IPP) approved by the Federal
                Government. <br />
                The multi-million-naira NSIA-LUTH Cancer Centre is the single
                largest Cancer Centre in West Africa, and has provided care to
                over 10,000 patients since 2019 when it was commissioned,
                thereby saving the country millions in foreign exchange that
                would have been expended on medical tourism. The Centre has 3
                Linear Accelerators, a CT Simulator, Brachytherapy machine, and
                a Chemotherapy suite (containing 14 Chemotherapy chairs). LUTH
                is now a go-to Hospital for Cancer Management in West Africa and
                is even attracting clients from abroad.
                <br />
                The 30-bed ICU where the deceased was admitted was funded and
                commissioned recently by the Federal Government and is the
                largest such facility in Nigeria. A 32-bed Dialysis Centre, with
                state-of-the-art machines has been in use for the past 2 years
                in LUTH. Several of the inpatient wards of the hospital, that
                were constructed about 60 years ago have been renovated and are
                currently in use. Other wards are currently undergoing
                renovations through budgetary provision by the FGN.
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>
                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Federal government recently provided funds to the hospital
                  to replace surgical instrument in all surgical specialties.
                  These instruments have not been replaced for a very long time.
                  <br />
                  The Federal government has also made funds available to LUTH
                  to upgrade endoscopic surgical services in Paediatric Surgery,
                  General Surgery, Urology and Gastroenterology. Endoscopic
                  procedures otherwise not possible a few years ago, are now
                  being carried out in LUTH. Other hospitals now refer patients
                  who require video-bronchoscopy to LUTH. Just last week, LUTH
                  became the first public tertiary hospital in Nigeria, to
                  perform Thoracoscopic Primary Repair of Oesophageal Atresia
                  with Tracheosophageal Fistula.
                  <br />
                  Our Laboratories: Haematology, Medical microbiology and
                  clinical pathology have also benefitted from infrastructural
                  renewals enabled by the FGN.
                  <br />
                  Kids Operating Room (KidsOR), a global health charity focused
                  on bringing equitable access to safe surgery for all children,
                  and Smile Train (a US-based NGO), supported LUTH with a grant
                  of over $500,000 for the renovation, furnishing and equipping
                  of two state-of-the-art (pediatric) operating theatres. These
                  theatres are dedicated to surgical procedures in children. The
                  theatres are up and running. A 4-Storey, 60-bed capacity ward
                  complex, the Alima Atta Oncology Wards (AAOW), was recently
                  donated to LUTH by Mrs. Iyabo Atta. This facility is the
                  single largest cancer ward in Nigeria and is fully equipped.
                  The AAOW is presently available for the admission of patients
                  needing care for cancer.
                  <br />
                  Recently, the Federal Government also provided LUTH with funds
                  to upgrade our Labour Ward, Labour Ward Theatres, and Neonatal
                  Unit (NNU). These facilities consist of 3-fully equipped
                  operating suites, 1-fully equipped endoscopic theatre,
                  12-fully equipped delivery suites, 2-fully equipped high
                  dependency units (HDU), 10-functional incubators and
                  13-phototherapy machines. The facility was recently
                  commissioned and has been put to use. About a week ago, the
                  FGN also approved immediate upgrade of health infrastructure
                  across Nigeria. As part of this upgrade, a Nuclear Medicine
                  Centre will be established, making available SPECT-Scan,
                  PET-Scan, Cyclotron and Radio-Pharmacy in LUTH. This
                  commendable effort will complement the existing
                  state-of-the-art NSIA-LUTH Cancer Center that has been in
                  operational since February 2019.
                  <br />
                  Efforts are ongoing to establish a Cardiac Catheterization
                  Laboratory at LUTH in 2024. This is an effort being driven by
                  the Federal Ministry of Health and Social Welfare under the
                  able leadership of Prof. Muhammed Ali Pate and Dr Tunji
                  Alausa. Furthermore, a Stem Cell Transplantation Centre has
                  been established at LUTH with a state-of-the-art Stem Cell
                  Laboratory. Plans are at an advanced stage to carry out the
                  first set of bone marrow transplant procedures at the centre,
                  on patients with sickle cell anaemia this year.
                  <br />
                  In line with the FGN’s efforts at continued infrastructural
                  renewal in LUTH, a new Outpatient Clinics Complex with 256
                  consulting rooms is currently under construction. When
                  complete, this complex will be fully equipped with
                  state-of-the-art facilities. In addition, a 4-storey
                  Geriatrics Centre for the care of the elderly, and another
                  3-Storey Dermatology and Rheumatology Centre of Excellence,
                  funded by the FGN are currently under construction at the
                  hospital. A 3-Storey DOT Centre, dedicated to the care of
                  patients living with tuberculosis and funded by the FGN is
                  also nearing completion.
                  <br />
                  These are exciting times for the healthcare sector in Nigeria,
                  and LUTH is poised to continue to partner with public and
                  private institutions to achieve the laudable goals for which
                  it was established in 1962.
                  <br />
                  The Management of LUTH wishes to assure the general public of
                  our commitment and dedication to continue to provide
                  qualitative and affordable healthcare to Nigerians. We are
                  committed to the policies and programmes of the Federal
                  Government of Nigeria and our parent Ministry (Ministry of
                  Health and Social Welfare) targeted at reviving and upgrading
                  the healthcare sector.
                  <br />
                  Thank you.
                </p>
                <p>
                  <strong>Dr. Ayodeji A. Oluwole</strong>
                  <br />
                  Chairman Medical Advisory Committee <br />
                  Lagos University Teaching Hospital
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span> */}
                {/* <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd10} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd11} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd12} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd13} height={370} />
                </div>
              </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
